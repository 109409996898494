var searchKeys = [{
  key: "categoryid",
  label: "类目",
  placeholder: "请选择",
  required: false,
  rules: [],
  cascader: true
  // select: true,
}, {
  key: "brandid",
  label: "品牌",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };