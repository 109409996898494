var columns = [{
  title: '类目名称',
  dataIndex: 'categoryname',
  key: 'categoryname',
  width: '15%',
  scopedSlots: {
    customRender: 'categoryname'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  // width: '25%',
  scopedSlots: {
    customRender: 'brandname'
  }
},
// {
//     title: '系列名称',
//     dataIndex: '',
//     key: '',
//     // width: '10%',
//     scopedSlots: {
//         customRender: ''
//     }
// },
// {
//     title: '是否支持AI鉴定',
//     dataIndex: '',
//     key: '',
//     // width: '10%',
//     scopedSlots: {
//         customRender: ''
//     }
// },
{
  title: 'AI鉴定/币',
  dataIndex: 'aicheckmoney',
  key: 'aicheckmoney',
  // width: '5%',
  scopedSlots: {
    customRender: 'aicheckmoney'
  }
}, {
  title: '普通/币',
  dataIndex: 'checkmoney',
  key: 'checkmoney',
  // width: '5%',
  scopedSlots: {
    customRender: 'checkmoney'
  }
}, {
  title: '普通结算/RMB',
  dataIndex: 'settlement',
  key: 'settlement',
  // width: '5%',
  scopedSlots: {
    customRender: 'settlement'
  }
}, {
  title: '普通时长/h',
  dataIndex: 'checktime',
  key: 'checktime',
  // width: '5%',
  scopedSlots: {
    customRender: 'checktime'
  }
}, {
  title: '快速/币',
  dataIndex: 'fastcheckmoney',
  key: 'fastcheckmoney',
  // width: '5%',
  scopedSlots: {
    customRender: 'fastcheckmoney'
  }
}, {
  title: '快速结算/RMB',
  dataIndex: 'fastsettlement',
  key: 'fastsettlement',
  // width: '5%',
  scopedSlots: {
    customRender: 'fastsettlement'
  }
}, {
  title: '快速时长/h',
  dataIndex: 'fastchecktime',
  key: 'fastchecktime',
  // width: '5%',
  scopedSlots: {
    customRender: 'fastchecktime'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  fixed: "right",
  width: 100,
  scopedSlots: {
    customRender: 'operation'
  }
}];
export { columns };